'use client';

import { useEffect, useRef } from 'react';

export function AppHasLoaded() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.setAttribute('data-testid', 'app-has-loaded');
  }, []);

  return <div ref={ref} />;
}
